import parse from "html-react-parser";
import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import {IAboutUsComponentProps} from '../../../lib/types/components';
import {dynamicCssSize, isMobile} from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";

const AboutUsComponentV2 = (props: IAboutUsComponentProps) => {
    const data = props.aboutUsData;

    return (
        data && Object.keys(data).length?
            <H2TrapeziumBG uniqueContainerId={"aboutUs_container"}>
                <style jsx>{`
                  .aboutUsParentContainer {
                    background-color: #ffffff;
                    position: relative;
                    color: white;
                    padding: 18px 0px 0 0;
                    font-family: var(--font-primary);
                    border-bottom: 2px solid;
                    border-image-source: linear-gradient(to right, #fcda04 44%, #fdea29 48%, #fef643 55%, #fffd52 62%, #ffff58 71%, #e0ca25 87%);
                    border-image-slice: 1;
                  }

                  .headingParent {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 25px;
                  }

                  .heading {
                    font-size: ${dynamicCssSize(60, 16)};
                    text-transform: uppercase;
                    font-weight: 900;
                    font-style: italic;
                    letter-spacing: 2.25px;
                    max-width: 65vw;
                    text-align: center;
                  }
                  
                  .aboutUsMainContainer {
                    position: relative;
                  }

                  .paraParentContainer {
                    padding: ${dynamicCssSize(70, 0)} 0px ${dynamicCssSize(600, 150)};
                  }

                  .paraContainer {
                    font-size: ${dynamicCssSize(25, 12)};
                    line-height: 1.31;
                    // max-height: 400px;
                    overflow: auto;
                    padding: 20px;
                    background-color: ${data.overlayColor};
                  }

                  .aboutWinZOBG {
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    min-width: 120%;
                    left: -10%;
                  }

                  hr {
                    border-image-source: linear-gradient(to right, #fcda04 44%, #fdea29 48%, #fef643 55%, #fffd52 62%, #ffff58 71%, #e0ca25 87%);
                    border-image-slice: 1;
                    margin: 0;
                  }

                  @media screen and (max-width: 767px) {
                    .heading {
                      line-height: 0.75;
                      letter-spacing: 1px;
                    }

                    .paraContainer {
                      // max-height: 160px;
                      padding-left: 35px;
                    }
                  }
                `}</style>
                <div className={"container-fluid aboutUsParentContainer"}>
                    <CustomImageComponent
                        src={data.img2 ? data.img2 : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/about_winzo_bg2.webp"}
                        layout={"fill"}
                        alt={"about_winzo_bg2"}
                        objectFit={"cover"}/>

                    <CustomImageComponent
                        src={data.img1 ? data.img1 : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/about_winzo_bg1.webp"}
                        layout={"fill"}
                        alt={"about_winzo_bg2"}
                        objectPosition={"bottom"}
                        objectFit={isMobile ? "contain" : "cover"}/>
                    <div className='container aboutUsMainContainer'>
                        <div className={"headingParent"}>
                            <h2 className={"heading"}>{data.heading}</h2>
                        </div>
                        <div className={"row paraParentContainer"}>
                            <div className={"col-9 col-md-8 paraContainer"}>
                                {data.para?.map((item, index) => {
                                    return (
                                        <div className={"aboutUsPara"} key={"data.para_" + index}>
                                            {parse(item)}
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <hr/>
            </H2TrapeziumBG> : <></>
    )
}

export default AboutUsComponentV2;
